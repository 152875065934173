// import Swiper from 'swiper'; 最小限の機能しか入っていない
// import Swiper from 'swiper';
// import Swiper,{Navigation, Pagenation} from 'swiper';
// import 'swiper/swiper.css'
// import 'swiper/swiper.min.css'

// import 'swiper/css';

// import 'swiper/module/navigation';
// import 'swiper/css/pagenation';


// import Swiper bundle with all modules installed
import Swiper from 'swiper/bundle';

// // import styles bundle
// import 'swiper/css/bundle';



const option = {
  loop: true,
  autoplay: true,
}

const sliderNav = new Swiper('.c-slider__nav', {
  // centeredSlides: true,
  loop: true,
  speed: 500,
  slidesPerView: 'auto',
  spaceBetween: 30,
  loop: true,
  pagination: {
    el: ".c-slider__nav__pagination",
    type: "fraction",
    clickable: false,
    formatFractionCurrent: function (number) {
      if (number < 10) {
          return '0' + number;
      } else {
          return number;
      }
    },
    formatFractionTotal: function (number) {
      if (number < 10) {
          return '0' + number;
      } else {
          return number;
      }
    }
  },
  navigation: {
    nextEl: ".c-slider__nav__controller__next",
    prevEl: ".c-slider__nav__controller__prev",
  },
});

// window.onload = function() {
  const sliderCaseWrapEl = document.querySelectorAll('.js-slider__case-detail');
  const sliderCaseMainEl = document.querySelectorAll('.c-slider__case-detail');
  const sliderCaseThumbEl = document.querySelectorAll('.c-slider__case-detail__thumb');

  for (let i = 0; i < sliderCaseWrapEl.length; i++) {
    var num = ('00' + (i+1)).slice(-2);
    sliderCaseWrapEl[i].className += num;
    sliderCaseMainEl[i].classList.add('js-slider__case-detail__main__' + num);
    sliderCaseThumbEl[i].classList.add('js-slider__case-detail__thumb__' + num);

    const sliderCaseThumb = new Swiper('.js-slider__case-detail__thumb__' + num, {
      spaceBetween: 10,
      slidesPerView: 4,
      spaceBetween: 10,
      breakpoints: {
        768:{
          slidesPerView: 6,
        },
        980:{
          slidesPerView: 10,
        }
      }
    });
    const sliderCase = new Swiper('.js-slider__case-detail__main__' + num, {
      loop: true,
      speed: 500,
      effect: 'fade',
      speed: 600,
      allowTouchMove:false,
      fadeEffect: {
        crossFade: true
      },
      thumbs: {
        swiper: sliderCaseThumb,
      }
    });
  }
// };



// breakpoint slider
const breakpoint =  window.matchMedia('screen and (min-width: 1024px)');
let indexWorksSlider;
const breakpointChecker = function () {

  // if larger viewport and multi-row layout needed
  if (breakpoint.matches === true) {

    // clean up old instances and inline styles when available
    if (indexWorksSlider !== undefined) indexWorksSlider.destroy(true, true);

    // or/and do nothing
    return;

    // else if a small viewport and single column layout needed
  } else if (breakpoint.matches === false) {

    // fire small viewport version of swiper
    return enableSwiper();
  }

};

//////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////

const enableSwiper = function () {

  indexWorksSlider = new Swiper('.c-slider__case', {
    centeredSlides: true,
    loop: true,
    speed: 500,
    slidesPerView: 1.2,
    spaceBetween: 40,
    loop: true,
    pagination: {
      el: ".c-slider__case__controller__pagination",
      type: "fraction",
      clickable: false,
      formatFractionCurrent: function (number) {
        if (number < 10) {
            return '0' + number;
        } else {
            return number;
        }
      },
      formatFractionTotal: function (number) {
        if (number < 10) {
            return '0' + number;
        } else {
            return number;
        }
      }
    },
    navigation: {
      nextEl: ".c-slider__case__controller__next",
      prevEl: ".c-slider__case__controller__prev",
    },
  });
};

//////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////

// keep an eye on viewport size changes
breakpoint.addListener(breakpointChecker);

// kickstart
breakpointChecker();




