
// import MicroModal from 'micromodal';
// // var MicroModal = require('micromodal');
// MicroModal.init();


// import { modal } from "./components/modal";
import './components/height.js';

import './components/slider.js';
import './components/modal.js';
import './components/slider-modal.js';
import './components/gsap.js';
import './components/header.js';
import './components/scrollTrigger.js';
