import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { ScrollToPlugin } from "gsap/ScrollToPlugin";

gsap.registerPlugin(ScrollTrigger, ScrollToPlugin);

// 画像群を連続的に表示するアニメーションの制御
// gsap
// .timeline({
//   defaults: { ease: "power2.out", duration: 0.5 },
//   scrollTrigger: {
//     markers: true, // マーカーを表示するか（開発用）
//     trigger: ".js-gsap-trigger", // この要素と交差するとイベントが発火
//     start: "-=50px center", // ウィンドウのどの位置を発火の基準点にするか
//     end: "bottom top", // ウィンドウのどの位置をイベントの終了点にするか
//     toggleActions: "play none none none", // スクロールイベントで発火するアニメーションの種類
//   },
// }).fromTo(".js-gsap-conti", {
//   opacity: 0,
//   y: 60,
//   },
//   {
//     opacity:1,
//     y:0,
//     ease: "power2.out",
//     // 複数要素を扱うプロパティ
//     stagger: {
//       from: "start",
//       amount: 0.2,
//     },
// });



gsap.from('.js-gsap-RtoL', {
  duration: 1,
  opacity: 0,
  x: 60,
  ease: "power3.out",
  stagger: {
    from: "start",
    amount: 1,
  },
  scrollTrigger: {
    trigger: '.js-gsap-RtoL',
    start: 'top 70%',
    // end: 'center center',
    toggleActions: 'play none none none',
    // markers: true,
    id: 'RtoL',
    //scrub: true,
    // scrub: 0.5 // smoothing
  }
});

gsap.from('.js-gsap-conti', {
  duration: 1.5,
  opacity: 0,
  y: 60,
  ease: "power3.out",
  stagger: {
    from: "start",
    amount: 1,
  },
  scrollTrigger: {
    trigger: '.js-gsap-conti--trigger',
    start: 'top 80%',
    end: 'center center',
    toggleActions: 'play none none none',
    // markers: true,
    id: 'box',
    //scrub: true,
    // scrub: 0.5 // smoothing
  }
});


gsap.utils.toArray('.js-gsap-basic').forEach( (item, index) => {
  gsap.from(item, {
    opacity: 0,
    y: 50,
    ease: "power3.out",
    duration: 2,
    scrollTrigger: {
      trigger: item,
      start: 'top 80%',
      end: 'bottom center',
      toggleActions: 'play none none none',
      // markers: true,
      id: 'gsap-img-' + index,
      //scrub: true,
      // scrub: 0.5 // smoothing
    }
  })
})

gsap.from('.js-gsap-panel', {
  opacity: 0,
  y: 100,
  ease: "power3.out",
  duration: 1.5,

  scrollTrigger: {
    trigger: '.js-gsap-panel--trigger',
    start: '30% center',
    // end: 'bottom center',
    toggleActions: 'play none none none',
    // markers: true,
    id: 'gsap-img',
    //scrub: true,
    // scrub: 0.5 // smoothing
  }
});

// pc以上の時に起動
ScrollTrigger.matchMedia({
  "(min-width: 1024px)" : function(){
    gsap.from('.js-gsap-slider', {
      duration: 2.5,
      opacity: 0,
      y: 60,
      ease: "power3.out",
      stagger: {
        from: "start",
        amount: 2,
      },
      scrollTrigger: {
        trigger: '.js-gsap-slider--trigger',
        start: 'top center',
        end: 'bottom 80%',
        toggleActions: 'play none none none',
        // markers: true,
        id: 'slider',
        //scrub: true,
        // scrub: 0.5 // smoothing
      }
    });
  }
});



// const showContent = () => {
//   // 以下のtween.play()とgsap.to()は同じことをしている
//   tween.play();
//   gsap.to("header h1", {
//     opacity: 1,
//   });
//   // 画像郡を連続的に表示するアニメーションの制御
//   gsap.to(".img-container img", {
//     opacity: 1,
//     delay: 1,
//     duration: 1.5,
//     y: -10,
//     ease: "power2.out",
//     // 複数要素を扱うプロパティ
//     stagger: {
//       from: "start",
//       amount: 0.8,
//     },
//   });
//   // スクロールイベントの制御
//   gsap
//     .timeline({
//       defaults: { ease: "power2.out", duration: 1.5 },
//       scrollTrigger: {
//         markers: true, // マーカーを表示するか（開発用）
//         trigger: ".content", // この要素と交差するとイベントが発火
//         start: "top 50%", // ウィンドウのどの位置を発火の基準点にするか
//         end: "bottom 25%", // ウィンドウのどの位置をイベントの終了点にするか
//         toggleActions: "play none none none", // スクロールイベントで発火するアニメーションの種類
//       },
//     })
//     .to(".js-scroll-slide", {
//       opacity: 1,
//       y: -10,
//     })
//     .to(
//       ".content-text p",
//       {
//         opacity: 1,
//         y: -10,
//       },
//       "-=1"
//     ) // 直前のアニメーションに0.7秒かぶせる
//     .to(
//       ".content img",
//       {
//         opacity: 1,
//         x: -10,
//       },
//       "-=1"
//     ); // 直前のアニメーションに0.7秒かぶせる
// };

// button.addEventListener("click", showContent);