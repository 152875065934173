window.addEventListener("scroll", function () {
  // heroパネル以上スクロール
  heroHeight();
});

window.addEventListener('DOMContentLoaded', function() {
  heroHeight();
});

function heroHeight(){
  // ヘッダーを変数の中に格納する
  const header = document.getElementById("js-header");
  const target = document.getElementById("js-hero");
  const triggerY = target.offsetHeight;
  header.classList.toggle("c-header__scrolling", window.scrollY > triggerY);
}