import Swiper from 'swiper/bundle';
const option = {
  loop: true,
  autoplay: true,
}

window.addEventListener('DOMContentLoaded', () => {
  const sliderCaseModalEl = document.querySelectorAll('.c-slider__case-detail__modal');
  const sliderCaseModalControllerEl = document.querySelectorAll('.c-slider__case-detail__modal__controller');
  for (let i = 0; i < sliderCaseModalEl.length; i++) {
    var num = ('00' + (i+1)).slice(-2);
    sliderCaseModalEl[i].classList.add('js-slider__case-detail__modal__' + num);
    sliderCaseModalControllerEl[i].classList.add('js-slider__case-detail__modal__controller' + num);
    const sliderCaseModal = new Swiper('.js-slider__case-detail__modal__' + num, {
      centeredSlides: true,
      loop: true,
      speed: 500,
      slidesPerView: 1,
      spaceBetween: 40,
      loop: true,
      pagination: {
        el: '.js-slider__case-detail__modal__controller' + num + " .c-slider__case-detail__modal__pagination",
        type: "fraction",
        clickable: false,
      },
      navigation: {
        nextEl: '.js-slider__case-detail__modal__controller' + num + " .c-slider__case-detail__modal__controller__next",
        prevEl: '.js-slider__case-detail__modal__controller' + num + " .c-slider__case-detail__modal__controller__prev",
      },
    });
    const sliderCaseMainEl = document.querySelectorAll('.c-slider__case-detail');
    const sliderCaseModalBtnEl = sliderCaseMainEl[i].querySelectorAll('[data-micromodal-trigger]');

    sliderCaseModalBtnEl.forEach(function (target,index) {
      // 引数targetにはdiv要素が1つずつ渡されている
      target.addEventListener('click', function () {
        sliderCaseModal.slideTo(index);
      });
    });
  }
});
