import MicroModal from 'micromodal';
// var MicroModal = require('micromodal');
MicroModal.init({
  disableScroll: true,
  awaitOpenAnimation: true,
  awaitCloseAnimation: true,
});

window.addEventListener('DOMContentLoaded', () => {
  const btn = document.querySelector('#js-header-menu');
  // メニューの開閉
  btn.addEventListener('click', function () {
    const bodyElement = document.querySelector('body');
    if (btn.ariaExpanded == 'false') {
      btn.ariaExpanded = true;
      MicroModal.show('modal-header',{
        disableScroll: true,
        awaitOpenAnimation: true,
        awaitCloseAnimation: true,
      });
      bodyElement.classList.add("is-open");
    } else {
      btn.ariaExpanded = false;
      MicroModal.close('modal-header', {
        disableScroll: true,
        awaitOpenAnimation: true,
        awaitCloseAnimation: true,
      });
      bodyElement.classList.remove("is-open");
    }
  });



});

