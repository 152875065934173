import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { ScrollToPlugin } from "gsap/ScrollToPlugin";

gsap.registerPlugin(ScrollTrigger, ScrollToPlugin);



// smooth scroll
// ---------------------------------------------
function getSamePageAnchor (link) {
  if (
    link.protocol !== window.location.protocol ||
    link.host !== window.location.host ||
    link.pathname !== window.location.pathname ||
    link.search !== window.location.search
  ) {
    return false;
  }
  return link.hash;
}

// Scroll to a given hash, preventing the event given if there is one
function scrollToHash(hash, e) {
  const elem = hash ? document.querySelector(hash) : false;
  if(elem) {
    if(e) e.preventDefault();
    const headerHeight = document.getElementById("js-header").offsetHeight;
    gsap.to(window, {
      scrollTo: {
        y: elem,
        offsetY: headerHeight + 30
      },
      ease: "Power3.easeOut"
    });
  }
}

const anchorElem = document.getElementsByClassName('js-anchor');
for( let i = 0 ; i < anchorElem.length ; i ++ ) {
  anchorElem[i].addEventListener('click', e => {
    scrollToHash(getSamePageAnchor(anchorElem[i]), e);
  });
}

// Scroll to the element in the URL's hash on load
scrollToHash(window.location.hash);